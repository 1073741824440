import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  

  {
    path: '/index/:route_id',
    name: 'index',
    //component: () => import('../views/AiotView.vue')
    //component: () => import('../views/GDView.vue')
    //component: () => import('../views/AiotView.vue')
    component: () => import('../views/RouteGPS.vue')
  }
  ,
  {
    path: '/test/:route_id',
    name: 'test',
    //component: () => import('../views/AiotView.vue')
    component: () => import('../views/RealGPS.vue')

  },
  {
    path: '/uniapp/:route_id',
    name: 'uniapp',
    //component: () => import('../views/AiotView.vue')
    component: () => import('../views/RouteGPS2.vue')

  }
  ,
  
  {
    path: '/gdws',
    name: 'gdws',
    //component: () => import('../views/AiotView.vue')
    component: () => import('../views/GDViewWS.vue')
  }
]

screenLeft
const router = new VueRouter({
  //mode:'history',
  routes
  
})


export default router
