
import axios from 'axios'

// 请求超时时间，10s
const requestTimeOut = 10*1000
const success = 200
// 更换令牌的时间区间
const checkRegion = 30 * 60 * 1000
// 提示信息显示时长
const messageDuration = 5 * 1000
//const baseURL = process.env.VUE_APP_API_BASE_URL
const baseURL = '/api'

// 系统全局请求对象  创建 axios 实例
const service = axios.create({
  baseURL: baseURL,
  timeout: requestTimeOut,
  responseType: 'json',
  validateStatus(status) {
    return status === success
  }
})

// request interceptor
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    return config
  },
  (e) => {
    Promise.reject(e)
  }
)


// response interceptor
/*
service.interceptors.response.use((response) => {
    return response.data
  }, err)*/
service.interceptors.response.use(
    (res) => {
      //return res?.data?.data || res
      return res.data
    },
    (e) =>{
      if(e.response.status===401){
        message.error('====')
      }
      return Promise.reject(e)}
  )


const err = (error) => {
  if (error.response) {
    const data = error.response.data
    //const token = getToken() //Vue.ls.get(ACCESS_TOKEN)  //
    if (error.response.status === 403) {
      alert({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
        alert({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      
    }
  }
  return Promise.reject(error)
}

const request = {

    refresh(url, params) {
      params['grant_type'] = 'refresh_token'
      return refresh_service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Authorization': authorizationValue
        }
      })
    },

    login(url, params) {
     
      
      return service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Authorization': authorizationValue
        }
      })
    },

    loginByClient(url){
      var params ={
        grant_type : 'client_credentials',
        scope: 'all'
      }
      return service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Authorization': authorizationValue
        }
      })      
    },
 
    post(url, params) {
      console.dir(params)
      if(params==null)
        return service.post(url,{
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            //'Content-Type': 'application/json;charset=UTF-8'
          }
        });
      else{
        return service.post(url, params, {
          transformRequest: [(params) => {
            return tansParams(params)
          }],
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
      }
    },
    put(url, params) {
      return service.put(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    get(url, params) {
      let _params
      if (Object.is(params, undefined)) {
        _params = ''
      } else {
        _params = '?'
        for (const key in params) {
          // eslint-disable-next-line no-prototype-builtins
          if (params.hasOwnProperty(key) && params[key] !== null) {
            _params += `${key}=${params[key]}&`
          }
        }
      }
      return service.get(`${url}${_params}`)
    },
    delete(url, params) {
      let _params
      if (Object.is(params, undefined)) {
        _params = ''
      } else {
        _params = '?'
        for (const key in params) {
          // eslint-disable-next-line no-prototype-builtins
          if (params.hasOwnProperty(key) && params[key] !== null) {
            _params += `${key}=${params[key]}&`
          }
        }
      }
      return service.delete(`${url}${_params}`)
    },
    download(url, params, filename) {
      //NProgress.start()
      return service.post(url, params, {
        transformRequest: [(params) => {
          return tansParams(params)
        }],
        responseType: 'blob'
      }).then((r) => {
        const content = r.data
        const blob = new Blob([content])
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a')
          elink.download = filename
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, filename)
        }
        //ress.done()
      }).catch((r) => {
        console.error(r)
        //NProgress.done()
        alert({
          message: '下载失败',
          type: 'error',
          duration: messageDuration
        })
      })
    },
    upload(url, params) {
      return service.post(url, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }
}

function tansParams(params) {
    let result = ''
    Object.keys(params).forEach((key) => {
      if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
        /*
        if(result!='')
          result += '&'+encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        else 
          result = encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          */
        result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
        // result += key+ '=' + params[key] + '&'
      }
    })
    result = result.substr(0,result.length-1);
    return result
  }
  
  
//pureAxios 有些地方要用到
export const pureAxios = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 6000 // 请求超时时间
})
pureAxios.interceptors.request.use(config => {
    const token = getToken() //Vue.ls.get(ACCESS_TOKEN)
    if (token) {
        config.headers['Authorization'] = 'bearer ' + token
        //config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
  }, err)
 

const installer = {
    vm: {},
    install (Vue) {
        Vue.use(VueAxios, service)
    }
}

export {
    request,
    installer as VueAxios,
    service as axios
  
}
